import React from "react";
import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import AppDownloadImg from "../assets/images/download_app.png";
import AppStore from "../assets/images/app-store.svg";
import GoogleStore from "../assets/images/play-store.svg";

export default function AppDownload() {
  return (
    <Container maxWidth="xl" className="downloadContainer">
      <Row className="rowCotainer">
        <Col sm={7} xs={12}>
          <Container maxWidth="md" className="mainTextContainer">
            <div className="socialName">
              <div>
                <div className="mainTextStyle">Download the app now</div>
              </div>
              <button className="social__medial-button-container">
                <img
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.pulsecareapp"
                    );
                  }}
                  src={GoogleStore}
                  alt="google_play"
                  className="storeContainer"
                />
              </button>
              <button className="social__medial-button-container">
                <img
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/kw/app/pulse-care/id1561836084"
                    );
                  }}
                  src={AppStore}
                  alt="app_store"
                  className="storeContainer"
                />
              </button>
            </div>
          </Container>
        </Col>
        <Col sm={5} xs={12}>
          <div className="downloadImgContainer">
            <img
              src={AppDownloadImg}
              alt="google_play"
              className="downloadAppImg"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
